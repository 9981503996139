/* stylelint-disable */

@tailwind base;
@tailwind components;
@tailwind utilities;
@layer components {
  #__next {
    @apply h-full bg-white;
  }
  html,
  body {
    @apply h-full;
  }
}

.firebase-emulator-warning {
  display: none !important;
}
